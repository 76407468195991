import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnInit,
   Output,
   SimpleChange,
   SimpleChanges,
} from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
   INBOUND_SKILL,
   MIXED_SKILL,
   OUTBOUND_SKILL,
} from "src/app/_interfaces/agente";
import {
   IAgentState,
   IAgentStateMonitor,
   IChannel,
} from "src/app/_interfaces/monitor";

export interface ISimpleChage extends SimpleChange {
   previousValue: {};
   currentValue: {};
}

export interface ISimpleChanges extends SimpleChanges {
   channels: ISimpleChage;
}

@Component({
   selector: "app-agente-state",
   templateUrl: "./agente-state.component.html",
   styleUrls: ["./agente-state.component.css"],
})
export class AgenteStateComponent implements OnInit, OnChanges {
   @Input() channels: IChannel[];
   @Input() currentTime: Observable<number>;
   @Input() currenQueue: string;
   @Input() agente: IAgentStateMonitor;

   @Output() agenteEmitter = new EventEmitter<IAgentState>();

   timeInState$: Observable<Date>;
   channel: { CallerID: string; UniqueID: string };

   get isMixed() {
      const { penalty } = this.agente;
      return penalty >= MIXED_SKILL.MIN && penalty <= MIXED_SKILL.MAX;
   }

   get isInbound() {
      const { penalty } = this.agente;
      return penalty >= INBOUND_SKILL.MIN && penalty <= INBOUND_SKILL.MAX;
   }

   get isOutbound() {
      const { penalty } = this.agente;
      return (
         penalty < 0 ||
         (penalty >= OUTBOUND_SKILL.MIN && penalty <= OUTBOUND_SKILL.MAX)
      );
   }

   get classColor() {
      switch (this.agente.status) {
         case "OFFLINE": {
            return "btn-default";
         }
         case "PAUSE": {
            return "btn-warning";
         }
         case "INBOUND_BUSY_PAUSE":
         case "INBOUND_BUSY": {
            return this.isCallInbound(this.agente)
               ? "btn-danger"
               : "btn-primary";
         }
         case "OUTBOUND_BUSY_PAUSE":
         case "OUTBOUND_BUSY": {
            return "btn-primary";
         }
         case "INBOUND_IDLE": {
            return "btn-success";
         }
         case "OUTBOUND_IDLE": {
            return "btn-info";
         }
         default: {
            return "btn-default";
         }
      }
   }

   get classIcon() {
      switch (this.agente.status) {
         case "PAUSE":
         case "INBOUND_BUSY_PAUSE":
         case "OUTBOUND_BUSY_PAUSE": {
            return "fa-clock-o";
         }
         default: {
            if (this.isMixed) {
               return "fa-random";
            }
            return "fa-user-circle-o";
         }
      }
   }

   constructor() {}

   ngOnChanges(changes: ISimpleChanges) {
      if (!!changes.channels && !!changes.channels.currentValue) {
         this.channel = this.getCurrentChannelInCall(this.agente);
      }
   }

   ngOnInit(): void {
      this.timeInState$ = this.currentTime.pipe(
         // filtrar por evento
         map((current) => {
            let timeInState: number;

            // Si esta en idle o en pausa
            if (
               this.agente.status.includes("_IDLE") ||
               this.agente.status === "PAUSE"
            ) {
               if (this.agente.last_call_date > this.agente.last_status_date) {
                  // Tiempo desde que terminó la última llamda.
                  timeInState = Number.parseInt(this.agente.last_call_date);
               } else if (
                  this.agente.last_call_date < this.agente.last_status_date
               ) {
                  // Tiempo desde que terminó la última pausa.
                  timeInState = Number.parseInt(this.agente.last_status_date);
               }
            }
            // Si esta ocupado y en esta cola.
            else if (this.agente.status.includes("_BUSY") && !!this.channel) {
               // Tiempo en el que inicio la llamada
               timeInState = Number.parseInt(this.agente.current_status_date);
            } else {
               timeInState = current;
            }
            //
            const auxTime = new Date().setHours(0, 0, 0, 0);
            // Evitar tiempos 23:59:XX
            if (current - timeInState > 0) {
               return new Date(auxTime + (current - timeInState));
            } else {
               return new Date(auxTime);
            }
         })
      );
   }

   lanzarAuxiliary() {
      if (this.agente.status !== "OFFLINE") {
         const agentMonitor = {
            idagente: this.agente.agent,
            modoAgente: this.agente.modalidad_agente,
            estado_idreason: this.agente.status_idReason,
            estado: this.agente.status,
            channel: this.channel,
            defaultPenalty: this.agente.penalty,
         };
         this.agenteEmitter.emit(agentMonitor as IAgentState);
      }
   }

   private getCurrentChannelInCall(currentAgent: IAgentStateMonitor): IChannel {
      // Corrección del canal si la llamada es outbound
      // Si la llamada es outbound
      if (!!currentAgent && !this.isCallInbound(currentAgent)) {
         // Buscar el canal con el cual esta unido.
         const canalDelUsuario = this.channels.find(
            (ch) => ch.UniqueID === currentAgent.bound_channel_id
         );

         const channelName = !!canalDelUsuario
            ? canalDelUsuario?.Channel.split(";").shift()
            : // No existe, construirlo
              `Local/${currentAgent.agent}@`;

         const finalChannel = this.channels.find((ch) => {
            return (
               ch.Channel.startsWith(channelName) &&
               ch.UniqueID !== canalDelUsuario?.UniqueID
            );
         });

         if (!!canalDelUsuario && !!finalChannel) {
            return finalChannel;
         } else if (!!finalChannel) {
            return { ...finalChannel, UniqueID: currentAgent.bound_channel_id };
         }

         return undefined;
      } else if (!!currentAgent) {
         return this.channels.find(
            (ch) => ch.UniqueID === currentAgent.bound_channel_id
         );
      }

      return undefined;
   }

   isCallInbound(agentState: IAgentStateMonitor) {
      return (
         !agentState.call_type ||
         agentState.call_type === "INBOUND" ||
         agentState.call_type === "CALLBACK" ||
         agentState.call_type === "CLICK2CALL" ||
         agentState.call_type === "DIALER_PREDICTIVO"
      );
   }
}
