<h4 class="text-muted"></h4>
<ng-container *ngIf="outboundCallRespose$ | async as callResponse">
    <form novalidate #frmDial="ngForm" (ngSubmit)="ejecutarLlamadaSaliente(frmDial.value)">
        <div class="form-group">
            <label>Número a marcar</label>
            <div class="input-group hidden-xs">
                <input type="tel" name="numero" class="form-control" ngModel name="numero" placeholder="Número a marcar..." required>
                <span class="input-group-btn">
                    <button type="submit" class="btn btn-success"
                    [disabled]="!frmDial.valid || callResponse.inProgress || (agente?.estado?.indexOf('PAUSE') < 0 && agente?.estado?.indexOf('IDLE') < 0)">
                        <i class="fa fa-phone"></i> Llamar</button>

                    <button [disabled]="!frmDial.valid || callResponse.inProgress || (agente?.estado?.indexOf('PAUSE') < 0 && agente?.estado?.indexOf('IDLE') < 0)" 
                    type="button" class="btn btn-success dropdown-toggle" 
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-caret-down"></i></button>
                    <ul class="dropdown-menu dropdown-menu-right">
                        <li *ngFor="let cola of colasDisponibles">
                            <a href="javascript:void(0)" (click)="ejecutarLlamadaSaliente(frmDial.value, cola)">Ejecutar llamada en {{cola}}</a></li>
                    </ul>
                </span>
            </div>
            <div class="visible-xs">
                <input type="tel" name="numero" class="form-control" ngModel name="numero" placeholder="Número a marcar..." required>
                <div class="text-right" style="margin-top: 10px;">
                    <button [disabled]="!frmDial.valid || callResponse.inProgress || (agente?.estado?.indexOf('PAUSE') < 0 && agente?.estado?.indexOf('IDLE') < 0)" 
                    type="button" class="btn btn-success dropdown-toggle" 
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Llamar usando... <i class="fa fa-caret-down"></i></button>
                    <ul class="dropdown-menu dropdown-menu-right">
                        <li *ngFor="let cola of colasDisponibles">
                            <a href="javascript:void(0)" (click)="ejecutarLlamadaSaliente(frmDial.value, cola)">Ejecutar llamada en {{cola}}</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!callResponse.inProgress && !!callResponse.callid">
        <div class="alert alert-success alert-dismissable" *ngIf="!callResponse.failed; else callFailed">
            <button type="button" class="close" data-dismiss="alert">&times;</button>
            <strong>Llamada conectada!.</strong>
         </div>
         <ng-template #callFailed>
            <button type="button" class="close" data-dismiss="alert">&times;</button>
            <strong>Llamada fallida!.</strong> La llamada no se pudo conectar.
         </ng-template>
      </ng-container>
        <div class="alert alert-info text-center" *ngIf="callResponse.inProgress">
            <strong><i class="fa fa-spinner fa-spin"></i> Llamada en progreso...</strong>
        </div>
    </form>
</ng-container>