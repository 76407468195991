<div class="btn-group-vertical btn-block" *ngIf="agente">

   <!-- Top of the agente -->
   <button type="button" class="btn btn-sm" [ngClass]="classColor">
      <div class="text-left row" style="font-size: 13px;">
         <div class="col-xs-6" style="padding-right: 0;">
            <i class="fa" [ngClass]="classIcon"></i> {{ agente?.agent }}
         </div>
         <div class="col-xs-6 text-right" style="padding-left: 0;">
            {{ timeInState$ | async | date: "HH:mm:ss"}}
         </div>
      </div>
   </button>

   <!-- Body of the agente -->
   <button type="button" class="btn btn-sm" [ngClass]="classColor" style="padding-top: 2px; line-height: 20px"
      (click)="lanzarAuxiliary()"
   >
      <div class="text-left row" style="font-size: 12px;">
         <div class="col-xs-12"><strong>{{agente.nombre | truncateNombre}}</strong></div>
         <!-- Inbound -->
         <div class="col-xs-4 text-left" style="padding-right: 5px;"><i class="fa fa-sign-in"></i> : {{agente.inbound}}
            <br>{{agente.tmo_inbound | tmoCalc: agente.inbound | date: 'mm:ss'}}
         </div>
         <!-- Outbound -->
         <div class="col-xs-4 text-center" style="padding-right: 5px; padding-left: 5px;"><i class="fa fa-sign-out"></i> : {{agente.outbound}}
            <br>{{agente.tmo_outbound | tmoCalc: agente.outbound | date: 'mm:ss'}}
         </div>
         <!-- Dialer -->
         <div class="col-xs-4 text-right" style="padding-left: 5px;"><i class="fa fa-tty"></i> : {{agente.dialer}}
            <br>{{agente.tmo_dialer | tmoCalc: agente.dialer | date: 'mm:ss'}}
         </div>
         <!-- Completes -->
         <div class="col-xs-4 text-left" style="padding-right: 5px;"><i class="fa fa-check-square-o"></i> : {{agente.complete_caller}}</div>
         <div class="col-xs-4 text-center" style="padding-right: 5px; padding-left: 5px;"><i class="fa fa-close"></i> : {{agente.complete_agent}}</div>
         <!-- Skill -->
         <div class="col-xs-4 text-right" style="padding-left: 5px;"><i class="fa fa-star"></i> : 
            <span *ngIf="isOutbound">O</span>
            <span *ngIf="isMixed">M </span>
            <span *ngIf="isInbound">{{agente.penalty}}</span>
         </div>
         <!-- Callerid -->
         <div class="col-xs-12 text-left"><i class="fa fa-volume-control-phone"></i> : {{ channel?.CallerID }}</div>
      </div>
   </button>
</div>