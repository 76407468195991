<div class="row" style="margin-bottom: 70px">
    <ng-container *ngIf="queueStats$ | async as stats">
        <div class="col-sm-3">
            <div class="panel panel-primary" style="margin: 0;" *ngIf="!!stats">
                <div class="panel-heading" style="padding: 15px;">
                    <div class="row" style="font-size: 11px; font-weight: 600px;">
                    <div class="col-xs-6 text-center" style="padding-left: 0; padding-right: 0;">
                        Nivel de Servicio <div style="font-size: 26px;" class="text-center">{{stats | sl | number: '0.0-1'}} %</div></div>
                    <div class="col-xs-6 text-center" style="padding-left: 0; padding-right: 0;">
                        Índice de Cumpl. <div style="font-size: 26px;" class="text-center">{{stats | ic | number: '0.0-1' }} %</div></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="panel panel-primary" *ngIf="!!stats">
                <div class="panel-body">
                    <div class="row" style="font-size: 11px; font-weight: 600px;">
                        <div class="col-md-3 col-xs-6 text-center">A. Entrantes <div style="font-size: 26px;" class="text-center">{{stats?.inbound}}</div></div>
                        <div class="col-md-3 col-xs-6 text-center">Salientes <div style="font-size: 26px;" class="text-center">{{stats?.outbound}}</div></div>
                        <div class="col-md-3 col-xs-6 text-center">Abandonos <div style="font-size: 26px;" class="text-center">{{stats?.abandon}}</div></div>
                        <div class="col-md-3 col-xs-6 text-center">Desistimiento <div style="font-size: 26px;" class="text-center">{{stats?.abandon_exit}}</div></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-5">
            <div class="panel panel-primary" *ngIf="!!stats">
                <div class="panel-body">
                    <div class="row" style="font-size: 11px; font-weight: 600px;">
                        <div class="col-md-3 col-xs-6 text-center" style="padding-left: 0; padding-right: 0;">
                            <span class="text-muted">TMO Entrantes</span> 
                            <div style="font-size: 26px;" class="text-center">
                                {{stats | inProm | toTime | date:'mm:ss' }}
                            </div>
                        </div>
                        <div class="col-md-3 col-xs-6 text-center" style="padding-left: 0; padding-right: 0;">
                            <span class="text-muted">TMO Salientes</span> <div style="font-size: 26px;" class="text-center">{{ stats | outProm | toTime | date:'mm:ss' }}</div></div>
                        <div class="col-md-3 col-xs-6 text-center" style="padding-left: 0; padding-right: 0;">
                            <span class="text-muted">Prom. Espera</span> <div style="font-size: 26px;" class="text-center">{{ stats | inHoldProm | toTime | date:'mm:ss' }}</div></div>
                        <div class="col-md-3 col-xs-6 text-center" style="padding-left: 0; padding-right: 0;">
                            <span class="text-muted">Prom. Abandono</span> <div style="font-size: 26px;" class="text-center">{{ stats | abandonProm | toTime | date:'mm:ss' }}</div></div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="queueState$ | async as queue">
        <div class="col-sm-3"> 
            <div class="panel panel-primary">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col-xs-8">
                            <i class="fa fa-headphones"></i> Agentes
                        </div>
                        <div class="col-xs-4 text-right">
                            <span class="badge">{{queue.agents?.length}}</span>
                        </div>
                    </div>
                </div>
                <ul class="list-group" style="margin-bottom: 0">
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-xs-8">
                                Agentes en Línea
                            </div>
                            <div class="col-xs-4 text-right">
                                <span class="badge">{{queue.agents | queueAgentStatus : true : 'PAUSE': 'OFFLINE'}}</span>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="row">
                        <div class="col-xs-8">
                            Agentes Ocupados
                        </div>
                        <div class="col-xs-4 text-right">
                            <span class="badge">{{queue.agents | queueAgentStatus : false : 'INBOUND_BUSY' : 'INBOUND_BUSY' : 'INBOUND_BUSY' : 'INBOUND_BUSY_PAUSE' : 'OUTBOUND_BUSY_PAUSE'}}</span>
                        </div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="row">
                        <div class="col-xs-8">
                            Agentes en Pausa
                        </div>
                        <div class="col-xs-4 text-right">
                            <span class="badge">{{queue.agents | queueAgentStatus : false : 'PAUSE'}}</span>
                        </div>
                        </div>
                    </li>
                </ul>
            </div>
            
            <div class="panel panel-primary" *ngIf="queueEntriesState$ | async as queueEntriesState">
                <div class="panel-heading">
                    <div class="row">
                    <div class="col-xs-8">
                        <i class="fa fa-random"></i> Llamadas en Cola
                    </div>
                    <div class="col-xs-4 text-right">
                        <span class="badge">{{queue?.state.callers.length || 0}}</span>
                    </div>
                    </div>
                </div>
                <ul class="list-group" style="margin-bottom: 0" *ngIf="currentTime$ | async as currentTime">

                    <li class="list-group-item" *ngIf="queueEntriesState.length > 0; else noCalls">
                        <div class="row" *ngFor="let c of queueEntriesState">
                            <div class="col-xs-8" style="font-size: 12px">
                                <i class="fa fa-arrow-circle-right"></i> {{c.position}}. {{c.callerID}} | Prio: {{c.priority}}
                            </div>
                            <div class="col-xs-4 text-right">
                                <span class="label label-info" style="font-size: 11px">
                                 {{currentTime | timeFromNow: c.entryTime | date: 'HH:mm:ss'}}
                              </span>
                            </div>
                        </div>
                    </li>
                    <ng-template #noCalls>
                     <li class="list-group-item text-center text-muted">
                        <small>No hay llamadas en espera. <i class="fa fa-thumbs-o-up"></i></small>
                     </li>
                    </ng-template>             
                </ul>
            </div>
            
            <!-- CALLBACK -->
            <div class="panel panel-primary" *ngIf="callbacksState$ | async; let callbacksState">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col-xs-9">
                            <i class="fa fa-clock-o"></i> Retorno de llamadas
                        </div>
                        <div class="col-xs-3 text-right">
                            <span class="badge">{{callbacksState?.length || 0}}</span>
                        </div>
                    </div>
                </div>
                <ul class="list-group">
                    <li class="list-group-item" *ngFor="let call of callbacksState">
                        <div class="row">
                            <div class="col-xs-6">
                                <div class="span" style="font-size: 10px">{{call.fecha}}</div>
                                {{call.phone}}
                            </div>
                            <div class="col-xs-6 text-right">
                                <button class="btn btn-sm btn-success" (click)="lanzarLlamada(call)">Lanzar</button>&nbsp;
                                <button class="btn btn-sm btn-danger" (click)="dismiss(call)"><i class="fa fa-trash"></i></button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
        <div class="col-sm-9">

            <ul class="nav nav-pills info" role="tablist" style="font-size: 13px">
                <li role="presentation" class="active">
                    <a href="#queue" aria-controls="queue" role="tab" data-toggle="tab">
                        <strong><i class="fa fa-dashboard"></i> Dashboard : {{queue?.nombre || queue?.queue}}</strong>
                    </a></li>
                <li role="presentation" >
                        <a href="#performance" aria-controls="performance" role="tab" data-toggle="tab">
                            <i class="fa fa-line-chart"></i> Rendimiento
                        </a>
                    </li>
                    
                <li role="presentation">
                        <a href="javascript:void(0)" (click)="editarQueue(queue.nombre || queue.queue)">
                            <i class="fa fa-edit"></i> Editar configuración
                        </a>
                    </li>
            </ul>
            <div class="tab-content" style="margin-top: 10px" >
                
                <div class="tab-pane fade in active" id="queue" *ngIf="channels$ | async as calls">
                  <div class="col-xs-6 col-sm-3 col-md-2 col-lg-2 text-center" style="margin-bottom: 10px; padding: 5px" 
                        *ngFor="let a of queue?.agents">
                        <app-agente-state 
                            [agente]="a" 
                            [currentTime]="currentTime$"
                            [currenQueue]="queue.nombre" 
                            [channels]="calls" 
                            (agenteEmitter)="cargarOPS($event)"></app-agente-state>
                    </div>
                </div>
                
                <div class="tab-pane fade" id="performance">
                    <form autocomplete="off" novalidate #f="ngForm">
                    <div class="row" style="margin-bottom: 10px">
                        <div class="col-md-8 hidden-xs text-muted" style="font-size: 17px; padding-top: 8px">
                            Indicador de Rendimiento de Agente X Hora
                        </div>
                        <div class="col-md-4 col-xs-12 text-right">
                            <div class="input-group">
                                <input type="text" ngDatePicker ngModel name="fecha" #fecha="ngModel" required>
                                <div class="input-group-btn">
                                    <button type="button" [disabled]="!f.valid" class="btn btn-primary" (click)="performance(fecha.value, queue.nombre)">
                                        <span *ngIf="!inProgress">Consultar</span>
                                        <span *ngIf="inProgress"><i class="fa fa-refresh fa-spin"></i> Consultando...</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </form>
                    
                    <div *ngIf="perfomenace$ | async as logs; else loading">
                        <table class="table table-striped" style="font-size: 12px; border: 1px solid #cccccc">
                            <thead style="background-color: #3073AD; color: white; font-weight: 600">
                                <tr>
                                    <td class="text-center">Agente</td>
                                    <td class="text-center" *ngFor="let d of logs.hora">{{d}}:00</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let a of logs.agente">
                                    <td class="" style="vertical-align: middle"><strong>{{a.name}}</strong></td>
                                    <td class="text-center" *ngFor="let ll of a.llamadas"
                                        [ngClass]="{'agAlerta': (ll.cant < 17 && ll.tiempo !== 0) || ll.tiempo > 185}"
                                        >
                                        <span [ngClass]="{'text-danger': ll.cant < 17 && ll.tiempo !== 0}"><i class="fa fa-phone"></i> {{ll.cant}}</span>
                                        <br>
                                        <span [ngClass]="{'text-danger txt-alerta': ll.tiempo > 185}">
                                            {{( ll.tiempo ) | toTime | date:'mm:ss'}}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="text-right">
                            <button type="button" class="btn btn-info" [disabled]="!logs" (click)="descargarCSV(logs)"><i class="fa fa-download"></i> Descargar</button>
                        </div>
                    </div>
                    <ng-template #loading>
                        <div style="padding: 40px" class="text-center">
                            <i class="fa fa-line-chart fa-3x text-muted" style="margin-bottom: 10px;"></i>
                            <h5 class="text-muted">Consulta el rendimiento de los agentes.</h5>
                        </div>
                    </ng-template>
                </div>
            </div>

        </div>
        <agent-op [agente]="toOpAgent" [cola]="queue?.nombre || queue?.queue"></agent-op>
    </ng-container>
</div>